export let WORDS = [
    "полка",
    "вилка",
    "ветка",
    "буфер",
    "опора",
    "рельс",
    "вагон",
    "билет",
    "букса",
    "поезд",
    "линия",
    "горка",
    "шпала",
]