export let WORDSBARRIER = [
    {name: "машинист", description: "Водитель ж/д транспорта"},
    {
        name: "реборда",
        description: "Как называется часть обода колеса, которая служит для предохранения от схода с рельса?"
    },
    {
        name: "остряк",
        description: "Как называется рельс, один конец которого специально обработан (заострён) и прилегает к рамному рельсу стрелки?"
    },
    {name: "руководящий", description: "Как называется наибольший по крутизне уклон на перегоне?"},
    {name: "хвост", description: "Он имеется у поезда, у кота, у собаки…."},
    {name: "насыпь", description: "Что является нижним строением пути?"},
    {name: "рефрижератор", description: "Как называется грузовой вагон, снабжённый холодильной системой?"},
    {
        name: "полувагон",
        description: "Как называется грузовой вагон без крыши с высокими бортами, предназначенный для перевозки навалочных грузов?"
    },
    {
        name: "башмак",
        description: "Как называется приспособление для уменьшения скорости или остановки движущихся групп вагонов (отцепов) и других видов подвижного состава?"
    },
    {name: "автосцепка", description: "Как называется устройство, соединяющее вагоны?"},
    {
        name: "автомобилевоз",
        description: "Как называется грузовой вагон, специального назначения для перевозки автомобилей."
    },
    {name: "цистерна", description: "Как называется вагон для перевозки жидких грузов?"},
    {name: "железо", description: "Из этого металла сделаны рельсы"},
    {
        name: "транспортёр",
        description: "Как называется специальный грузовой вагон, предназначенный для перевозки крупногабаритных и тяжеловесных грузов"
    },
    {
        name: "трансферкар",
        description: "Как называется саморазгружающийся и самодвижущийся грузовой вагон, используемый на металлургических предприятиях для подачи сырья к доменным печам."
    },
    {
        name: "вагонетка",
        description: "Как называется небольшой вагон для перевозки угля в шахтах"
    },
    {
        name: "платформа",
        description: "Как называется  грузовой вагон открытого типа, предназначенный для перевозки длинномерных, штучных грузов, контейнеров и оборудования, не требующих защиты от атмосферных воздействий."
    },
    {name: "бандаж", description: "Часть железнодорожного колеса, напрессованная на колесный центр."},
    {name: "билет", description: "Посадочный купон для посадки в поезд"},
    {name: "проводник", description: "Человек, помогающий пассажирам во время поездки в поезде"},
    {name: "тамбур", description: "Место в вагоне, где осуществляются посадка/высадка пассажиров"},
    {name: "тепловоз", description: "Вид локомотива, которому не нужна контактная сеть для движения"},
    {name: "магистраль", description: "Альтернативное название железной дороги"},
    {name: "вокзал", description: "Здание на железнодорожной станции, предназначенное для обслуживания пассажиров и их грузов"},
    {name: "электричка", description: "Устоявшееся название поезда в пригородном сообщении"},
    {name: "ласточка", description: "В честь какой птицы назван скоростной электропоезд, производимы на Урале"},
    {name: "иволга", description: "В честь какой птицы назван российский электропоезд, производимый в Твери"},
    {name: "паровоз", description: "Как называется локомотив, который работает на угле"},
    {name: "касса", description: "Где на вокзале продаются билеты на поезд"},
    {name: "пантограф", description: "Конструкция на верхней части электропоезда, предназначенная для отбора электричества с контактной сети"},
    {name: "метро", description: "Вид железнодорожного транспорта, который перевозит пассажиров под землей"},
    {name: "мост", description: "По этому искусственному сооружению поезд двигается, при переезде через реки и овраги"},
    {name: "гудок", description: "Сигнал, который подает машинист при отправлении поезда со станции"},
]