<template>
  <div id="puzzle">
<!--    <header>
      <div class="title">
        <img src="../assets/pazzle-title.png" alt="pazzle-title">
      </div>
    </header>-->

    <Board ref="board" v-show="playing" @restart="restart" />
    <OptionsPane ref="optionsPane" @gameStart="start" v-show="!playing" />

  </div>
</template>
<script>
import Board from '../components/Board'
import OptionsPane from '../components/OptionsPane'

export default {
  name: 'app',
  components: {
    Board,
    OptionsPane
  },

  data () {
    return {
      playing: false,
      screenWidth: 0,
    }
  },

  methods: {
    start (...args) {
      this.playing = true
      this.$refs.board.start(...args)
    },

    restart () {
      this.playing = false
      this.$refs.optionsPane.reset()
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
}
</script>

<style lang="scss">
*, *::before, *::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #368ba0;
}

#puzzle {
  font: 14px/20px sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding-bottom: 16px;
  padding-top: 16px;

  header {
    .title {
      width: 200px;
      margin: 0 auto;
      padding-top: 8px;

      img{
        width: 100%;
      }
    }
  }

  footer {
    color: #555;
    margin-top: 60px;
  }
}
</style>
