<template>
  <div class="game-board">
    <div
        class="tile"
        :class="getTileClass(index % tileCount, Math.floor(index / tileCount))"
        v-for="(tile, index) in Array(tileCount * tileCount)"
        :key="index"
        @click="
        () => console.log(index % tileCount, Math.floor(index / tileCount))
      "
    ></div>
    <div class="boom" v-if="boom">
      <img src="@/assets/boom.png" alt="boom">
    </div>
  </div>
</template>

<script>
import methodsMixin from "@/mixins/methodsMixin";

export default {
  name: "GameBoard",
  mixins: [methodsMixin],
  props: {
    tileCount: {
      type: Number,
      required: true,
    },
    snake: {
      type: Object,
      required: true,
    },
    food: {
      type: Object,
      required: true,
    },
    boom: Boolean
  },
  methods: {
    getTileClass(x, y) {
      const head = this.snake[0];
      return {
        "snake-head": head.x === x && head.y === y,
        "snake-body": this.isSnake(x, y) && !(head.x === x && head.y === y),
        food: this.isFood(x, y),
      };
    },
  },
};
</script>

<style lang="scss">
.game-board {
  max-width: 60vh;
  width: 100%;
  grid-column: 1 / -1;
  align-self: center;
  box-shadow: 0 0.5rem 2rem -1rem rgba(0, 0, 0, 0.1);
  /* border-radius: 1rem; */
  display: grid;
  grid-template-columns: repeat(var(--tile-count), 1fr);
  align-content: start;
  gap: 1px;
  background-color: #003affba;
  border: 1px solid #003affba;
  position: relative;
  .tile {
    aspect-ratio: 1/1;
    background-color: #ffffff;
    border: none;
  }
}



.snake-head {
  /*background-color: var(--col-sec);*/
  background-image: url("@/assets/locomotive.svg");
}

.snake-body {
  /* background-color: var(--col-prim);*/
  background-image: url("@/assets/wagon.svg");
}

.food {
  /*background-color: var(--col-food-apple);*/
  background-image: url("@/assets/target.svg");
}
.boom {
  width: 400px;
  height: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -140px;
  margin-left: -200px;
  animation: boom 1.5s ease 0s 1 normal forwards;
  img{
    width: 100%;
    height: auto;
  }
}

@keyframes boom {
  0% {
    opacity: 1;
    transform: scale(0.2);
  }

  80% {
    opacity: 0;
    transform: scale(1.2);
  }

  100% {
    opacity: 0;
    transform: scale(2.2);
  }
}
</style>
