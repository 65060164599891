<template>
  <div class="game-logo">
    <picture>
      <img
          class="game-logo-img"
          src="@/assets/locomotive.svg"
          alt="locomotive Logo"
      />
    </picture>
    <div class="game-logo-text">
      <h1 class="game-logo-title">Собери как можно больше грузов</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameLogo",
};
</script>

<style lang="scss">
.game-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 10px;
  font-size: 12px;
  @media (max-width: 1366px) {
    display: none;
  }
}

.game-logo-img {
  display: block;
  width: 6rem;
}

.game-logo-text {
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-logo-title {
  font-size: 2rem;
  font-weight: 800;
  color: #3b9198;
}

.game-logo-subtitle {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--col-txt-dark);
}

</style>
