<template>
  <div class="barrier">
    <div class="barrier-svg">
      <div class="double" v-show="showDoubel">Уже было!</div>
      <div class="result" v-show="vin || loose">
        <h2 v-if="vin">Гигант мысли! Поздравляю</h2>
        <h2 v-if="loose">Слабовато! Слово: "{{ word.name.toUpperCase() }}"</h2>
        <div class="buttons">
          <button @click="restartGame">Еще раз</button>
          <button @click="closeGame">Выход</button>
        </div>
      </div>
      <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="-17 0 512.006 512.006"
          style="enable-background: new 0 0 512.006 512.006"
          xml:space="preserve"
      >
        <path
            style=""
            d="M123.626,137.727H88.889v-32.145c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v32.145H39.151 c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h34.737v108.412c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V152.727h34.737 c4.143,0,7.5-3.358,7.5-7.5S127.769,137.727,123.626,137.727z"
            fill="#545454"
        ></path>
        <path
            style=""
            d="M103.022,422.877H59.755V274.531c0-11.948,9.686-21.634,21.634-21.634l0,0 c11.948,0,21.634,9.686,21.634,21.634L103.022,422.877L103.022,422.877z"
            fill="#666666"
        ></path>
        <path
            style=""
            d="M452.766,422.877h-30.905V267.199c0-2.209,1.791-4,4-4h22.905c2.209,0,4,1.791,4,4V422.877z"
            fill="#9C9C9C"
        ></path>
        <rect
            x="421.861"
            y="345.613"
            style=""
            width="30.905"
            height="77.26"
            fill="#666666"
        ></rect>
        <path
            style=""
            d="M54.46,105.003h53.858c1.858,0,2.772-2.246,1.437-3.529L82.826,75.581 c-0.801-0.771-2.073-0.771-2.875,0l-26.929,25.893C51.687,102.757,52.602,105.003,54.46,105.003z"
            fill="#666666"
        ></path>
        <!--        <circle
                    style=""
                    cx="80"
                    cy="80"
                    r="20.604"
                    :fill="leftSemafor ? 'red' : '#FFFFFF'"
                    stroke="#9C9C9C"
                ></circle>-->
        <circle
            style=""
            cx="39.151"
            cy="145.223"
            r="20.604"
            :fill="leftSemafor ? 'red' : '#FFFFFF'"
            stroke="#9C9C9C"
        ></circle>
        <circle
            style=""
            cx="123.621"
            cy="145.223"
            r="20.604"
            :fill="rightSemafor ? '#FFFFFF' : 'red'"
            stroke="#9C9C9C"
        ></circle>
        <g class="rotate" :style="{ transform: `rotate( ${degRotate}deg)` }">
          <path
              style="fill: rgb(250, 41, 41)"
              d="M450.319,107.556c1.068,2.004,0.313,4.494-1.688,5.568L22.624,341.728 c-7.486,4.017-16.797,1.181-20.802-6.336l0,0c-4.008-7.524-1.181-16.886,6.312-20.907L434.102,85.902 c2.01-1.078,4.513-0.319,5.585,1.694L450.319,107.556z"
              fill="#FAA228"
          ></path>
          <polygon
              style=""
              points="167.339,264.071 140.205,278.632 140.299,243.563 167.432,229.003 "
              fill="#EFEFEF"
          ></polygon>
          <polygon
              style=""
              points="225.632,232.79 198.499,247.35 198.592,212.281 225.726,197.721 "
              fill="#EFEFEF"
          ></polygon>
          <polygon
              style=""
              points="283.926,201.508 256.793,216.068 256.886,181 284.02,166.439 "
              fill="#EFEFEF"
          ></polygon>
          <polygon
              style=""
              points="342.22,170.226 315.086,184.787 315.18,149.718 342.313,135.158 "
              fill="#EFEFEF"
          ></polygon>
          <polygon
              style=""
              points="400.514,138.945 373.38,153.505 373.474,118.436 400.607,103.876 "
              fill="#EFEFEF"
          ></polygon>
        </g>
      </svg>
    </div>
    <div class="word-description">
      <p>Вводите буквы только на русской раскладке!</p>
      <span>{{ word.description }}</span>
    </div>
    <div class="word">
      <div class="word-name" v-for="(letter, index) in word.name" :key="index">
        {{ correctLetters.includes(letter) ? letter.toUpperCase() : "" }}
      </div>
    </div>
    <div class="wrong">
      <span>Ошибки:</span>
      <span
          v-for="(letter, index) in wrongLetters"
          :key="index"
          class="wrong-letters"
          v-show="wrongLetters.length"
      >
        {{
          index !== wrongLetters.length - 1
              ? `${letter.toUpperCase()}, `
              : letter.toUpperCase()
        }}
      </span>
    </div>
    <div id="keyboard" class="keyboard">
      <div class="row" v-for="(row, i) in rows" :key="i">
        <div class="spacer" v-if="i === 1"></div>
        <button
            v-for="key in row"
            @click="keyupKeyboardHandler(key)"
        >
          <span v-if="key !== 'Backspace'">{{ key }}</span>
          <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
          >
            <path
                fill="currentColor"
                d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"
            ></path>
          </svg>
        </button>
        <div class="spacer" v-if="i === 1"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import {computed, onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {WORDSBARRIER} from "@/components/wordsBarrier";

const router = useRouter();
const leftSemafor = ref(true);
const rightSemafor = ref(true);
const vin = ref(false);
const loose = ref(false);
const word = computed(() => WORDSBARRIER[getRandomInRange(0, WORDSBARRIER.length - 1)]);
const letters = ref([]);
const degRotate = ref(28);

const correctLetters = computed(() =>
    letters.value.filter((x) => word.value.name.includes(x))
);
const wrongLetters = computed(() =>
    letters.value.filter((x) => !word.value.name.includes(x))
);
const counterWrong = computed(() => wrongLetters.value.length);

function getRandomInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const rows = [
  'ёйцукенгшщзхъ'.split(''),
  'фывапролджэ'.split(''),
  'ячсмитьбю'.split(''),
]
const keyupKeyboardHandler = (key) => {
  if (vin.value || loose.value) {
    return
  }
  if (letters.value.includes(key)) {
    doubelLetter();
  } else {
    letters.value.push(key.toLowerCase());
  }
}

window.addEventListener("keydown", ({key}) => {
  if (vin.value || loose.value) {
    return
  }
  if (letters.value.includes(key)) {
    doubelLetter();
  } else if (/[а-яА-ЯёЁ]/.test(key)) {
    letters.value.push(key.toLowerCase());
  }
});
//---------семафор в начале----------
let go = setInterval(() => {
  leftSemafor.value = !leftSemafor.value;
  rightSemafor.value = !rightSemafor.value;
}, 1000);

//---------- семафор-------------

watch(counterWrong, (newQuestion, oldQuestion) => {
  if (newQuestion < 6) {
    degRotate.value += 18;
  }
  if (counterWrong.value === 5) {
    clearInterval(go);
    leftSemafor.value = true;
    rightSemafor.value = false;
    loose.value = true
  }
});
watch(correctLetters, (newQuestion, oldQuestion) => {
  if ([...word.value.name].every(x => correctLetters.value.includes(x))) {
    vin.value = true
    clearInterval(go);
    leftSemafor.value = false;
    rightSemafor.value = true;
  }
})

//---------- Мигание при дубле буквы---------
const showDoubel = ref(false);
const doubelLetter = () => {
  let doubel = setInterval(() => (showDoubel.value = !showDoubel.value), 300);
  setTimeout(() => {
    clearInterval(doubel);
  }, 2000);
};
const restartGame = () => {
  window.location.reload();
}
const closeGame = () => {
  router.push({path: "/"});
}
//------------------local storage---------
const localStorageValue = ref('')

const setLocalStorageContent = () => {
  localStorage.setItem('barrier', word.value.name)
}

const showLocalStorageContent = () => {
  localStorageValue.value = localStorage.getItem('barrier')
}

const removeLocalStorageContent = () => {
  localStorageValue.value = localStorage.removeItem('item')
}
showLocalStorageContent()

if (localStorageValue.value === word.value.name) {
  window.location.reload();
} else {
  setLocalStorageContent()
}

//----------------------------------------------------------------------------------
onMounted(() => {
  setTimeout(() => {
    degRotate.value = -62;
  }, 500);
});
</script>


<style scoped lang="scss">
.barrier {
  margin-top: 16px;
}

h2 {
  color: #255d62;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 16px;
}

.barrier-svg {
  max-width: 600px;
  height: auto;
  display: flex;
  align-items: end;
  justify-content: center;
  //border: 1px solid red;
  margin: auto;
  background-image: url("../assets/barrier.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  .double {
    position: absolute;
    bottom: 36px;
    color: red;
    font-weight: bold;
    font-size: 22px;
  }

  svg {
    max-width: 269px;
    height: 420px;
    stroke: black;
    stroke-width: 2px;
    transform: translate(-19px, 54px);

    @media (max-width: 1366px) {
      transform: translate(-19px, 32px);
      max-width: 230px;
      height: 360px;
    }

    @media (max-width: 480px) {
      transform: translate(-17px, 31px);
      height: 350px;
      width: 205px;
    }
  }

  .result {
    width: 400px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    padding: 16px;
    gap: 20px;
    top: 50%;
    left: 50%;
    background-color: rgba(37, 93, 98, 0.7);
    z-index: 200;
    @media (max-width: 480px) {
      width: 250px;
      height: 200px;
    }

    h2 {
      color: white;
      @media (max-width: 480px) {
        font-size: 16px;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 38px;
      color: white;

      button {
        padding: 8px 16px;
        border-radius: 20px;
        border: none;
        cursor: pointer;
        transition: all 0.3s;
        opacity: 0.9;

        &:hover {
          -webkit-box-shadow: 8px 10px 8px 0px rgba(34, 60, 80, 0.6);
          -moz-box-shadow: 8px 8px 8px 0px rgba(34, 60, 80, 0.6);
          box-shadow: 8px 8px 8px 0px rgba(34, 60, 80, 0.6);
          opacity: 1;
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }
}

.rotate {
  // transform: rotate(-62deg);
  //transform: rotate(-12deg);
  transition: all 2s;
  transform-origin: 63px 281px;
}

.word-description {
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 6px;
  }
  @media (min-width: 769px) {
    width: 600px;
    margin: 8px auto;
  }
  @media (min-width: 1366px) {
    width: 800px;
    margin: 8px auto;
  }

  p {
    color: red;
    margin-bottom: 8px;
    font-size: 12px;
    @media (max-width: 1366px) {
      display: none;
    }
  }

}

.word {
  display: flex;
  justify-content: center;
  gap: 4px;

  .word-name {
    color: #255d62;
    font-weight: bold;
    font-size: 32px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid black;
    @media (max-width: 1366px) {
      font-size: 16px;
      width: 40px;
      height: 40px;
    }
    @media (max-width: 480px) {
      font-size: 14px;
      width: 22px;
      height: 22px;
    }
  }
}

.wrong {
  height: 20px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: bold;
  font-size: 22px;
  transition: all 0.3s ease;

  @media (max-width: 1366px) {
    font-size: 16px;
    gap: 6px;
    margin-top: 8px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    gap: 4px;
    margin-top: 6px;
    justify-content: flex-start;
  }

  .wrong-letters {
    color: red;
  }
}
</style>


<style scoped lang="scss">
#keyboard {
  margin-top: 20px;
  margin-bottom: 20px;
  user-select: none;

  @media (max-width: 480px) {
    margin-top: 10px;
  }
}

.row {
  display: flex;
  width: 100%;
  margin: 0 auto 8px;
  touch-action: manipulation;
  @media (max-width: 320px) {
    margin: 0 auto 4px;
  }

  button {
    font-family: inherit;
    font-weight: bold;
    border: 0;
    padding: 4px 8px;
    margin: 0 6px 0 0;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    background-color: #d3d6da;
    color: #1a1a1b;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s;

    &:active {
      background-color: azure;
    }

    @media (min-width: 220px) {
      padding: 4px;
      margin: 0 4px 0 0;
      font-size: 10px;
    }
    @media (min-width: 480px) {
      padding: 4px;
      margin: 0 4px 0 0;
      font-size: 14px;
    }
    @media (min-width: 768px) {
      padding: 12px 8px;
      margin: 0 6px 0 0;
      font-size: 20px;
    }
  }

  button:last-of-type {
    margin: 0;
  }
}

.spacer {
  flex: 0.5;
}

button.big {
  flex: 1.5;
}

.keyboard {
  display: none;
  @media (max-width: 1366px) {
    display: block;
  }
}
</style>