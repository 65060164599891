<template>
  <nav>
    <router-link to="/snake/">
      <picture>
        <img
            class="game-logo-img"
            src="@/assets/locomotive.svg"
            alt="locomotive Logo"
        />
      </picture>
      Веселый паровоз
    </router-link>
    <router-link to="/barrier/">
      <picture>
        <img
            class="game-logo-img"
            src="@/assets/barrier.svg"
            alt="barrier Logo"
        />
      </picture>
      Шлагбаум
    </router-link>
    <router-link to="/puzzle/">
      <picture>
        <img
            class="game-logo-img"
            src="@/assets/pazzle.jpg"
            alt="puzzle Logo"
        />
      </picture>
      Пазлы
    </router-link>
    <router-link to="/word/">
      <picture>
        <img
            class="game-logo-img"
            src="@/assets/word.jpg"
            alt="word Logo"
        />
      </picture>
      Угадай слово
    </router-link>

  </nav>

</template>
<script setup>
</script>

<style scoped lang="scss">
nav {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > a {
    width: 200px;
    height: 100px;
    background-color: #3b9198;
    font-weight: bold;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 20px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;

    @media (min-width: 320px) {
      width: 300px;
      font-size: 18px;
    }
    @media (min-width: 480px) {
      width: 360px;
      font-size: 20px;
      padding: 8px 20px;
    }

    @media (min-width: 768px) {
      width: 400px;
      font-size: 28px;
      padding: 8px 24px;
    }

    &.router-link-exact-active {
      color: #42b983;
    }

    img {
      transition: all 0.3s;
    }

    &:hover {
      -webkit-box-shadow: 4px 4px 10px 0px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 4px 4px 10px 0px rgba(34, 60, 80, 0.2);
      box-shadow: 4px 4px 10px 0px rgba(34, 60, 80, 0.2);

      img {
        filter: grayscale(0);
      }
    }
  }
}
</style>
