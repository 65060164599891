<template>
  <div class="game-score-wrapper">
    <div class="game-score">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0
        512 512"
        xml:space="preserve"
      >
        <path
          style=""
          d="M211.2,41.467L98.133,174.8c-10.667,12.8-1.067,30.933,14.933,30.933h225.067 c16,0,24.533-19.2,14.933-30.933L240,41.467C232.533,31.867,218.667,31.867,211.2,41.467z"
          fill="#F8B64C"
        ></path>
        <path
          style=""
          d="M297.6,20.133l-125.867,150.4c-11.733,13.867-2.133,35.2,16,35.2h252.8 c18.133,0,27.733-21.333,16-35.2l-125.867-150.4C322.133,10.533,306.133,10.533,297.6,20.133z"
          fill="#FFD15C"
        ></path>
        <path
          style="fill: rgb(219, 20, 60)"
          d="M407.467,423.333H104.533c-20.267,0-37.333-13.867-41.6-33.067L17.067,208.933h476.8L448,390.267 C444.8,409.467,426.667,423.333,407.467,423.333z"
          fill="#40596B"
        ></path>
        <g>
          <path
            style=""
            d="M475.733,245.2H36.267C16,245.2,0,229.2,0,208.933l0,0c0-20.267,16-36.267,36.267-36.267h439.467 c20.267,0,36.267,16,36.267,36.267l0,0C512,229.2,496,245.2,475.733,245.2z"
            fill="#334A5E"
          ></path>
          <circle
            style=""
            cx="163.2"
            cy="423.333"
            r="75.733"
            fill="#334A5E"
          ></circle>
          <circle
            style=""
            cx="348.8"
            cy="423.333"
            r="75.733"
            fill="#334A5E"
          ></circle>
        </g>
        <circle
          style="fill: rgb(58, 104, 156)"
          cx="348.8"
          cy="423.333"
          r="35.2"
          fill="#CDD6E0"
        ></circle>
        <path
          style="fill: rgb(16, 115, 213)"
          d="M348.8,398.8c18.133,0,32,12.8,35.2,29.867c0-2.133,0-3.2,0-5.333c0-19.2-16-35.2-35.2-35.2 s-35.2,16-35.2,35.2c0,2.133,0,3.2,0,5.333C316.8,411.6,330.667,398.8,348.8,398.8z"
          fill="#ACB3BA"
        ></path>
        <circle
          style="fill: rgb(58, 104, 156)"
          cx="163.2"
          cy="423.333"
          r="35.2"
          fill="#CDD6E0"
        ></circle>
        <path
          style="fill: rgb(16, 115, 213)"
          d="M163.2,398.8c18.133,0,32,12.8,35.2,29.867c0-2.133,0-3.2,0-5.333c0-19.2-16-35.2-35.2-35.2 s-35.2,16-35.2,35.2c0,2.133,0,3.2,0,5.333C131.2,411.6,145.067,398.8,163.2,398.8z"
          fill="#ACB3BA"
        ></path>
      </svg>
      {{ score }}
    </div>
    <h3>Грузов собрано</h3>
  </div>
</template>

<script>
import { mdiFoodApple } from "@mdi/js";

export default {
  name: "GameScore",

  props: {
    score: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      mdiFoodApple,
    };
  },
};
</script>

<style>
.game-score-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.game-score {
  display: flex;
  align-items: center;
  gap: 0.6rem;

  font-size: 1.6rem;
  font-weight: 800;
  color: var(--col-food-apple);
  width: 50px;
  height: 50px;
}
</style>
