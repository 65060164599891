<template>
  <div class="words">
    <div class="header">
      <p>Задумано слово на ж/д тематику. Введи любое слово из пяти букв и нажми Enter.</p>
      <p><span>Желтый</span> цвет - буква есть, но не в данной позиции. </p>
      <p><span>Зеленый</span> цвет - буква есть и находится в данной позиции.</p>
    </div>
    <div id="container" v-if="uiOpen">
      <div id="open-sequence" v-if="openingSequence">
        <span> {{ titleSequence }}</span>
      </div>
      <div class="result" v-show="vin || loose">
        <h2 v-if="vin">Гигант мысли! Поздравляю</h2>
        <h2 v-if="loose">{{ `Слабовато! Слово: "${this.theWord}"` }}</h2>
        <div class="buttons">
          <button @click="restartGame">Еще раз</button>
          <button @click="closeGame">Выход</button>
        </div>
      </div>
      <div class="line-box" v-show="!openingSequence">
        <div class="letter" ref="10" :style="{backgroundColor: getLetterColor(firstLineFinal, 0)}">
          <span>{{ firstLine[0] ? firstLine[0] : "" }}</span></div>
        <div class="letter" ref="11" :style="{backgroundColor: getLetterColor(firstLineFinal, 1)}">
          <span>{{ firstLine[1] ? firstLine[1] : "" }}</span></div>
        <div class="letter" ref="12" :style="{backgroundColor: getLetterColor(firstLineFinal, 2)}">
          <span>{{ firstLine[2] ? firstLine[2] : "" }}</span></div>
        <div class="letter" ref="13" :style="{backgroundColor: getLetterColor(firstLineFinal, 3)}">
          <span>{{ firstLine[3] ? firstLine[3] : "" }}</span></div>
        <div class="letter" ref="14" :style="{backgroundColor: getLetterColor(firstLineFinal, 4)}">
          <span>{{ firstLine[4] ? firstLine[4] : "" }}</span></div>
      </div>
      <div class="line-box" v-show="!openingSequence">
        <div class="letter" ref="20" :style="{backgroundColor: getLetterColor(secondLineFinal, 0)}">
          <span>{{ secondLine[0] ? secondLine[0] : "" }}</span></div>
        <div class="letter" ref="21" :style="{backgroundColor: getLetterColor(secondLineFinal, 1)}">
          <span>{{ secondLine[1] ? secondLine[1] : "" }}</span></div>
        <div class="letter" ref="22" :style="{backgroundColor: getLetterColor(secondLineFinal, 2)}">
          <span>{{ secondLine[2] ? secondLine[2] : "" }}</span></div>
        <div class="letter" ref="23" :style="{backgroundColor: getLetterColor(secondLineFinal, 3)}">
          <span>{{ secondLine[3] ? secondLine[3] : "" }}</span></div>
        <div class="letter" ref="24" :style="{backgroundColor: getLetterColor(secondLineFinal, 4)}">
          <span>{{ secondLine[4] ? secondLine[4] : "" }}</span></div>
      </div>
      <div class="line-box" v-show="!openingSequence">
        <div class="letter" ref="30" :style="{backgroundColor: getLetterColor(thirdLineFinal, 0)}">
          <span>{{ thirdLine[0] ? thirdLine[0] : "" }}</span></div>
        <div class="letter" ref="31" :style="{backgroundColor: getLetterColor(thirdLineFinal, 1)}">
          <span>{{ thirdLine[1] ? thirdLine[1] : "" }}</span></div>
        <div class="letter" ref="32" :style="{backgroundColor: getLetterColor(thirdLineFinal, 2)}">
          <span>{{ thirdLine[2] ? thirdLine[2] : "" }}</span></div>
        <div class="letter" ref="33" :style="{backgroundColor: getLetterColor(thirdLineFinal, 3)}">
          <span>{{ thirdLine[3] ? thirdLine[3] : "" }}</span></div>
        <div class="letter" ref="34" :style="{backgroundColor: getLetterColor(thirdLineFinal, 4)}">
          <span>{{ thirdLine[4] ? thirdLine[4] : "" }}</span></div>
      </div>
      <div class="line-box" v-show="!openingSequence">
        <div class="letter" ref="40" :style="{backgroundColor: getLetterColor(fourthLineFinal, 0)}">
          <span>{{ fourthLine[0] ? fourthLine[0] : "" }}</span></div>
        <div class="letter" ref="41" :style="{backgroundColor: getLetterColor(fourthLineFinal, 1)}">
          <span>{{ fourthLine[1] ? fourthLine[1] : "" }}</span></div>
        <div class="letter" ref="42" :style="{backgroundColor: getLetterColor(fourthLineFinal, 2)}">
          <span>{{ fourthLine[2] ? fourthLine[2] : "" }}</span></div>
        <div class="letter" ref="43" :style="{backgroundColor: getLetterColor(fourthLineFinal, 3)}">
          <span>{{ fourthLine[3] ? fourthLine[3] : "" }}</span></div>
        <div class="letter" ref="44" :style="{backgroundColor: getLetterColor(fourthLineFinal, 4)}">
          <span>{{ fourthLine[4] ? fourthLine[4] : "" }}</span></div>
      </div>
      <div class="line-box" v-show="!openingSequence">
        <div class="letter" ref="50" :style="{backgroundColor: getLetterColor(fifthLineFinal, 0)}">
          <span>{{ fifthLine[0] ? fifthLine[0] : "" }}</span></div>
        <div class="letter" ref="51" :style="{backgroundColor: getLetterColor(fifthLineFinal, 1)}">
          <span>{{ fifthLine[1] ? fifthLine[1] : "" }}</span></div>
        <div class="letter" ref="52" :style="{backgroundColor: getLetterColor(fifthLineFinal, 2)}">
          <span>{{ fifthLine[2] ? fifthLine[2] : "" }}</span></div>
        <div class="letter" ref="53" :style="{backgroundColor: getLetterColor(fifthLineFinal, 3)}">
          <span>{{ fifthLine[3] ? fifthLine[3] : "" }}</span></div>
        <div class="letter" ref="54" :style="{backgroundColor: getLetterColor(fifthLineFinal, 4)}">
          <span>{{ fifthLine[4] ? fifthLine[4] : "" }}</span></div>
      </div>
      <div class="line-box" v-show="!openingSequence">
        <div class="letter" ref="60" :style="{backgroundColor: getLetterColor(sixthLineFinal, 0)}">
          <span>{{ sixthLine[0] ? sixthLine[0] : "" }}</span></div>
        <div class="letter" ref="61" :style="{backgroundColor: getLetterColor(sixthLineFinal, 1)}">
          <span>{{ sixthLine[1] ? sixthLine[1] : "" }}</span></div>
        <div class="letter" ref="62" :style="{backgroundColor: getLetterColor(sixthLineFinal, 2)}">
          <span>{{ sixthLine[2] ? sixthLine[2] : "" }}</span></div>
        <div class="letter" ref="63" :style="{backgroundColor: getLetterColor(sixthLineFinal, 3)}">
          <span>{{ sixthLine[3] ? sixthLine[3] : "" }}</span></div>
        <div class="letter" ref="64" :style="{backgroundColor: getLetterColor(sixthLineFinal, 4)}">
          <span>{{ sixthLine[4] ? sixthLine[4] : "" }}</span></div>
      </div>
    </div>
    <div class="word-description">
      <p>Вводите буквы только на русской раскладке!</p>
    </div>
  </div>
  <div id="keyboard" class="keyboard">
    <div class="row" v-for="(row, i) in this.rows">
      <div class="spacer" v-if="i === 1"></div>
      <button
          v-for="key in row"
          @click="keyupKeyboardHandler(key)"
      >
        <span v-if="key !== 'Backspace'">{{ key }}</span>
        <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
        >
          <path
              fill="currentColor"
              d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"
          ></path>
        </svg>
      </button>
      <div class="spacer" v-if="i === 1"></div>
    </div>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import {WORDS} from '@/components/words'

const Letters =
    ["А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И", "Й", "К", "Л", "М", "Н", "О", "П", "Р", "С", "Т", "У", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Ъ", "Ы", "Ь", "Э", "Ю", "Я"]


const sleep = m => new Promise(r => setTimeout(r, m))

export default {
  name: 'WordGame',
  components: {},
  data() {
    return {
      router: useRouter(),
      uiOpen: true,
      openingSequence: false,
      titleSequence: "Старт...",
      chosenWord: ["В", "А", "Г", "О", "Н"],
      firstLine: [],
      firstLineFinal: [],
      firstRound: true,
      secondLine: [],
      secondLineFinal: [],
      secondRound: false,
      thirdLine: [],
      thirdLineFinal: [],
      thirdRound: false,
      fourthLine: [],
      fourthLineFinal: [],
      fourthRound: false,
      fifthLine: [],
      fifthLineFinal: [],
      fifthRound: false,
      sixthLine: [],
      sixthLineFinal: [],
      sixthRound: false,
      vin: false,
      loose: false,
      theWord: '',
      rows: [
        'ёйцукенгшщзхъ'.split(''),
        'фывапролджэ'.split(''),
        ['Enter', ...'ячсмитьбю'.split(''), 'Backspace']
      ]
    }
  },
  methods: {
    eventHandler: function () {
      //let action = event.data.action;
      this.openingSequence = false
      let sWords = this.shuffle(WORDS);
      let newWord = sWords[Math.floor(Math.random() * sWords.length)].toUpperCase();
      this.chosenWord = newWord.split("");
      this.uiOpen = true;
      // this.beginSequence();
      this.theWord = newWord
      console.log(newWord)
      // if (action === "close") {
      //   return this.close()
      // }
    },
    keyupHandler: function (e) {
      if (this.vin || this.loose) {
        return
      }
      if (e.which === 27) {
        this.close()
        return this.postMessage('didWin', {success: false})
      } else if (e.which === 13) {
        return this.solve()
      } else if (e.which === 8) {
        return this.removeLetter()
      } else {
        return this.addLetter(e)
      }
    },
    keyupKeyboardHandler: function (e) {
      if (this.vin || this.loose) {
        return
      }
      if (e === 'Enter') {
        return this.solve()
      } else if (e === 'Backspace') {
        return this.removeLetter()
      } else {
        return this.addKeyboardLetter(e)
      }
    },
    beginSequence: async function () {
      this.titleSequence = "Отгадайте слово"
      await sleep(1500)
      this.titleSequence = "на железнодорожную"
      await sleep(1500)
      this.titleSequence = "тематику..."
      await sleep(1500)
      this.titleSequence = ""
      this.uiOpen = true
      this.openingSequence = false
    },
    close: function () {
      this.firstRound = true
      this.secondRound = false
      this.thirdRound = false
      this.fourthRound = false
      this.fifthRound = false
      this.sixthRound = false
      this.firstLine = []
      this.secondLine = []
      this.thirdLine = []
      this.fourthLine = []
      this.fifthLine = []
      this.sixthLine = []
      this.firstLineFinal = []
      this.secondLineFinal = []
      this.thirdLineFinal = []
      this.fourthLineFinal = []
      this.fifthLineFinal = []
      this.sixthLineFinal = []
      this.openingSequence = false
      let letters = document.getElementsByClassName("letter");
      for (let i = 0; i < letters.length; i++) {
        letters[i].classList.remove("flipInY");
      }
      //location.reload()
      return this.uiOpen = true
    },
    solve: async function () {
      let correct = true
      if (this.firstRound && this.firstLine.length === 5) {
        this.firstRound = false
        this.secondRound = true
        let letters = [...this.firstLine]
        for (let i = 0; i < 5; i++) {
          this.$refs[Number(`${1}${i}`)].classList.add("flipInY");
          this.firstLineFinal.push(letters.shift());
          await sleep(500)
        }
        for (let i = 0; i < 5; i++) {
          if (this.chosenWord[i] !== this.firstLineFinal[i]) {
            correct = false
          }
        }
        await this.checkWinner(correct, false)
      } else if (this.secondRound && this.secondLine.length === 5) {
        this.secondRound = false
        this.thirdRound = true
        let letters = [...this.secondLine]
        for (let i = 0; i < 5; i++) {
          this.$refs[Number(`${2}${i}`)].classList.add("flipInY");
          this.secondLineFinal.push(letters.shift());
          await sleep(500)
        }
        for (let i = 0; i < 5; i++) {
          if (this.chosenWord[i] !== this.secondLineFinal[i]) {
            correct = false
          }
        }
        await this.checkWinner(correct, false)
      } else if (this.thirdRound && this.thirdLine.length === 5) {
        this.thirdRound = false
        this.fourthRound = true
        let letters = [...this.thirdLine]
        for (let i = 0; i < 5; i++) {
          this.$refs[Number(`${3}${i}`)].classList.add("flipInY");
          this.thirdLineFinal.push(letters.shift());
          await sleep(500)
        }
        for (let i = 0; i < 5; i++) {
          if (this.chosenWord[i] !== this.thirdLineFinal[i]) {
            correct = false
          }
        }
        await this.checkWinner(correct, false)
      } else if (this.fourthRound && this.fourthLine.length === 5) {
        this.fourthRound = false
        this.fifthRound = true
        let letters = [...this.fourthLine]
        for (let i = 0; i < 5; i++) {
          this.$refs[Number(`${4}${i}`)].classList.add("flipInY");
          this.fourthLineFinal.push(letters.shift());
          await sleep(500)
        }
        for (let i = 0; i < 5; i++) {
          if (this.chosenWord[i] !== this.fourthLineFinal[i]) {
            correct = false
          }
        }
        await this.checkWinner(correct, false)
      } else if (this.fifthRound && this.fifthLine.length === 5) {
        this.fifthRound = false
        this.sixthRound = true
        let letters = [...this.fifthLine]
        for (let i = 0; i < 5; i++) {
          this.$refs[Number(`${5}${i}`)].classList.add("flipInY");
          this.fifthLineFinal.push(letters.shift());
          await sleep(500)
        }
        for (let i = 0; i < 5; i++) {
          if (this.chosenWord[i] !== this.fifthLineFinal[i]) {
            correct = false
          }
        }
        await this.checkWinner(correct, false)
      } else if (this.sixthRound && this.sixthLine.length === 5) {
        let letters = [...this.sixthLine]
        for (let i = 0; i < 5; i++) {
          this.$refs[Number(`${6}${i}`)].classList.add("flipInY");
          this.sixthLineFinal.push(letters.shift());
          await sleep(500)
        }
        for (let i = 0; i < 5; i++) {
          if (this.chosenWord[i] !== this.sixthLineFinal[i]) {
            correct = false
          }
        }
        await this.checkWinner(correct, true)
      }
    },
    removeLetter: function () {
      if (this.firstRound) {
        this.firstLine.pop();
      } else if (this.secondRound) {
        this.secondLine.pop();
      } else if (this.thirdRound) {
        this.thirdLine.pop();
      } else if (this.fourthRound) {
        this.fourthLine.pop();
      } else if (this.fifthRound) {
        this.fifthLine.pop();
      } else if (this.sixthRound) {
        this.sixthLine.pop();
      }
    },
    addLetter: function (e) {
      let key = e.key.toUpperCase()
      if (Letters.includes(key)) {
        if (this.firstRound && this.firstLine.length < 5) {
          this.firstLine.push(key)
        } else if (this.secondRound && this.secondLine.length < 5) {
          this.secondLine.push(key)
        } else if (this.thirdRound && this.thirdLine.length < 5) {
          this.thirdLine.push(key)
        } else if (this.fourthRound && this.fourthLine.length < 5) {
          this.fourthLine.push(key)
        } else if (this.fifthRound && this.fifthLine.length < 5) {
          this.fifthLine.push(key)
        } else if (this.sixthRound && this.sixthLine.length < 5) {
          this.sixthLine.push(key)
        }
      }
    },
    addKeyboardLetter: function (e) {
      let key = e.toUpperCase()
      if (Letters.includes(key)) {
        if (this.firstRound && this.firstLine.length < 5) {
          this.firstLine.push(key)
        } else if (this.secondRound && this.secondLine.length < 5) {
          this.secondLine.push(key)
        } else if (this.thirdRound && this.thirdLine.length < 5) {
          this.thirdLine.push(key)
        } else if (this.fourthRound && this.fourthLine.length < 5) {
          this.fourthLine.push(key)
        } else if (this.fifthRound && this.fifthLine.length < 5) {
          this.fifthLine.push(key)
        } else if (this.sixthRound && this.sixthLine.length < 5) {
          this.sixthLine.push(key)
        }
      }
    },
    getLetterColor: function (letters, index) {
      if (this.firstLine.length < 5) return;
      let letter = letters[index]
      if (letter) {
        if (this.chosenWord.includes(letter)) {
          let wordIDX = this.chosenWord.indexOf(letter);
          if (wordIDX !== -1 && wordIDX === index) return 'green'
          let wordIDXLast = this.chosenWord.lastIndexOf(letter);
          if (wordIDXLast !== -1 && wordIDXLast === index) return 'green'
          return '#c1c41a' // Yellow
        }
        return 'grey'
      } else {
        return ''
      }
    },
    shuffle: function (arr) {
      let shuffled = arr
          .map(value => ({value, sort: Math.random()}))
          .sort((a, b) => a.sort - b.sort)
          .map(({value}) => value)
      return shuffled;
    },
    postMessage: function (endpoint, data) {
      if (data.success === true) {
        this.vin = true
      } else {
        this.loose = true
      }
      console.log(data)
    },
    checkWinner: async function (solved, doEnd) {
      if (solved) {
        await sleep(1000)
        this.close()
        this.postMessage('didWin', {success: true})
      } else if (doEnd) {
        await sleep(1000)
        this.close()
        this.postMessage('didWin', {success: false})
      }
    },
    restartGame: function () {
      this.vin = false
      this.loose = false
      this.eventHandler()
    },
    closeGame: function () {
      this.router.push({path: "/"});
    },
  },

  mounted() {
    //window.addEventListener('message', this.eventHandler);
    this.vin = false
    this.loose = false
    this.eventHandler()
    document.addEventListener('keydown', this.keyupHandler);
  },
  beforeUnmount() {
    // window.removeEventListener('message', this.eventHandler);
    document.removeEventListener('keydown', this.keyupHandler);
    this.uiOpen = false
  },
}

</script>

<style lang="scss">
#container {
  position: relative;
  margin: 0 auto;
  background-color: #3B9198;
  width: 200px;
  height: 260px;
  top: 42%;

  @media (min-width: 320px) {
    width: 280px;
    height: 320px;
    top: 42%;
  }
  @media (min-width: 480px) {
    width: 420px;
    height: 480px;
    top: 42%;
  }

  @media (min-width: 1367px) {
    width: 55vh;
    height: 64vh;
    top: 50%;
  }
}

.header {
  max-width: 600px;
  padding: 8px;
  margin: 16px auto;
  font-size: 12px;
  background-color: white;
  border: 1px solid rgba(37, 93, 98, 1);
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (max-width: 480px) {
    font-size: 10px;
  }

  p:nth-child(2) {
    span {
      color: #c1c41a;
    }
  }

  p:nth-child(3) {
    span {
      color: green;
    }
  }
}

#open-sequence {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 25%;
  background-color: rgba(37, 93, 98, 1);
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

#open-sequence span {
  color: white;
  font-size: 1.5rem;
}

.line-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  vertical-align: middle;
  align-items: center;
  width: 100%;
  height: 16.66666666666667%;
}

.letter {
  border: 2px solid rgb(255, 255, 255);
  width: 18%;
  height: 90%;
  margin: 3px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  background-color: rgba(37, 93, 98, 1);
}

.letter span {
  color: white;
  font-size: 5vh;
  font-weight: bold;

  @media (min-width: 480px) {
    font-size: 6vh;
  }

  @media (min-width: 768px) {
    font-size: 7vh;
  }
  @media (min-width: 1025px) {
    font-size: 8vh;
  }
}

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  animation: flipInY 2s ease;
  transition: .5s;
}

h2 {
  color: #255d62;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 16px;
}

.result {
  width: 90%;
  border: 1px solid white;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  padding: 16px;
  gap: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(37, 93, 98, 0.9);
  z-index: 200;

  h2 {
    color: white;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 38px;
    color: white;

    button {
      padding: 8px 16px;
      border-radius: 20px;
      border: none;
      cursor: pointer;
      transition: all 0.3s;
      opacity: 0.9;

      &:hover {
        -webkit-box-shadow: 8px 10px 8px 0px rgba(34, 60, 80, 0.6);
        -moz-box-shadow: 8px 8px 8px 0px rgba(34, 60, 80, 0.6);
        box-shadow: 8px 8px 8px 0px rgba(34, 60, 80, 0.6);
        opacity: 1;
      }

      &:active {
        box-shadow: none;
      }
    }
  }
}
.word-description p {
  color: red;
  font-size: 16px;
  margin-top: 8px;
  @media (max-width: 1366px) {
    display: none;
  }
}
</style>

<style scoped lang="scss">
#keyboard {
  margin-top: 20px;
  margin-bottom: 20px;
  user-select: none;

  @media (max-width: 480px) {
    margin-top: 10px;
  }
}

.row {
  display: flex;
  width: 100%;
  margin: 0 auto 8px;
  touch-action: manipulation;
  @media (max-width: 320px) {
    margin: 0 auto 4px;
  }

  button {
    font-family: inherit;
    font-weight: bold;
    border: 0;
    padding: 4px 8px;
    margin: 0 6px 0 0;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    background-color: #d3d6da;
    color: #1a1a1b;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s;

    &:active {
      background-color: azure;
    }

    @media (min-width: 220px) {
      padding: 4px;
      margin: 0 4px 0 0;
      font-size: 10px;
    }
    @media (min-width: 480px) {
      padding: 4px;
      margin: 0 4px 0 0;
      font-size: 14px;
    }
    @media (min-width: 768px) {
      padding: 12px 8px;
      margin: 0 6px 0 0;
      font-size: 20px;
    }
  }

  button:last-of-type {
    margin: 0;
  }
}

.spacer {
  flex: 0.5;
}

button.big {
  flex: 1.5;
}

.keyboard {
  display: none;
  @media (max-width: 1366px) {
    display: block;
  }
}
</style>