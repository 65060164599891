<template>
  <form id="optionsForm" @submit.prevent="start">
    <img :src="image" v-if="image" alt="img">
    <div>
      <button v-if="image" @click="reset" style="margin-right: 16px">Другая картинка</button>
      <button v-if="image">Старт</button>

    </div>
  </form>
</template>

<script>
import loadImage from 'blueimp-load-image'

export default {
  data() {
    return {
      image: null,
      size: {
        horizontal: 3,
        vertical: 3
      },
      screenWidth: 0,
      randomImage: null,
      localStorageValue: null,
    }
  },

  methods: {
    fileChanged() {
      if (this.screenWidth <= 480) {
        loadImage(require(`../assets/puzzle-${this.randomImage}.jpg`), canvas => {
          this.image = canvas.toDataURL()
        }, {
          maxWidth: 300,
          maxHeight: 300,
          minWidth: 200,
          minHeight: 200,
          canvas: true
        })
      } else if (this.screenWidth > 480 && this.screenWidth < 768) {
        loadImage(require(`../assets/puzzle-${this.randomImage}.jpg`), canvas => {
          this.image = canvas.toDataURL()
        }, {
          maxWidth: 400,
          maxHeight: 400,
          minWidth: 200,
          minHeight: 200,
          canvas: true
        })
      } else if (this.screenWidth > 767 && this.screenWidth < 1024) {
        loadImage(require(`../assets/puzzle-${this.randomImage}.jpg`), canvas => {
          this.image = canvas.toDataURL()
        }, {
          maxWidth: 700,
          maxHeight: 500,
          minWidth: 200,
          minHeight: 200,
          canvas: true
        })
      } else {
        loadImage(require(`../assets/puzzle-${this.randomImage}.jpg`), canvas => {
          this.image = canvas.toDataURL()
        }, {
          maxWidth: 800,
          maxHeight: 600,
          minWidth: 300,
          minHeight: 300,
          canvas: true
        })
      }

    },
    /**
     * Start the game by emitting the event.
     */
    start() {
      this.$emit('gameStart', {
        image: this.image,
        size: this.size
      })
    },

    /**
     * Reset the options.
     */
/*    reset() {
      this.image = null
      this.randomImage = null
      document.querySelector('#optionsForm').reset()
      this.getRandomInRange(0, 2)
      this.fileChanged()
      this.setLocalStorageContent()
    },*/
    reset() {
      this.image = null
      this.randomImage = null
      this.getRandomInRange(0, 17)
      this.showLocalStorageContent()
      if (this.localStorageValue === this.randomImage) {
        this.reset()
      } else {
        this.fileChanged()
        this.setLocalStorageContent()
      }
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    getRandomInRange(min, max) {
      this.randomImage = Math.floor(Math.random() * (max - min + 1)) + min;
    },
    setLocalStorageContent() {
      localStorage.setItem('puzzle', this.randomImage)
    },
    showLocalStorageContent() {
      this.localStorageValue = +localStorage.getItem('puzzle')
    },
  },
  computed: {},
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
    this.getRandomInRange(1, 17)
    this.fileChanged()

    this.showLocalStorageContent()
    if (this.localStorageValue === this.randomImage) {
      this.reset()
    } else {
      this.setLocalStorageContent()
    }
  },
}
</script>

<style lang="scss" scoped>
img {
  //border: 1px solid #ccc;
  margin-bottom: 8px;
}

label[for=file] {
  color: #368ba0;
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
}

input[type=number] {
  height: 24px;
  font-size: 14px;
  border: 1px solid #ccc;
}

input[type=file] {
  display: none;
}

button {
  -webkit-appearance: none;
  padding: 6px 12px;
  background: #1ca76a;
  color: #fff;
  border-radius: 3px;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
