import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomePage.vue";
import SnakeView from "../views/SnakeView.vue";
import BarrierGame from "@/views/BarrierGame.vue";
import PuzzleGame from "@/views/PuzzleGame.vue";
import WordGame from "@/views/WordGame.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "main" },
    component: Home,
  },
  {
    path: "/snake/",
    name: "SnakeView",
    meta: { layout: "main" },
    component: SnakeView,
  },
  {
    path: "/barrier/",
    name: "BarrierGame",
    meta: { layout: "main" },
    component: BarrierGame,
  },
  {
    path: "/puzzle/",
    name: "PuzzleGame",
    meta: { layout: "main" },
    component: PuzzleGame,
  },
  {
    path: "/word/",
    name: "WordGame",
    meta: { layout: "main" },
    component: WordGame,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
