<template>
  <div class="main-layout">
    <header role="banner">
      <a href="https://opzt.ru/">
        <img src="@/assets/opztlogo.png" alt="opzt logo"/>
      </a>
      <div class="back-link" v-show="route.path !== '/'">
        <router-link to="/"> Назад</router-link>
      </div>
      <h1>Игры ОПЖТ</h1>
    </header>
    <main class="main" role="main">
      <div class="content">

        <router-view/>
      </div>
    </main>
    <footer role="contentinfo"></footer>
  </div>
</template>

<script setup>
import {useRoute} from "vue-router";

const route = useRoute();
</script>

<style scoped lang="scss">
.main-layout {
  margin: 0 auto;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  background-image: url("@/assets/fon-all.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "main"
    "footer";
}

header {
  grid-area: header;
  //  box-shadow: 0 1px 2px rgba(31, 37, 47, 0.1);
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: white;
  padding-left: 28px;
  padding-right: 28px;
  @media (max-width: 480px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  a {
    width: 120px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: inherit;
      height: auto;
    }
  }
}

main {
  grid-area: main;
  width: 100%;
  display: flex;
  transition: all 0.3s;
  min-width: 0;
  overflow: auto;

  .content {
    width: 100%;
    transition: width 0.3s ease;
    position: relative;
    padding-right: 4px;
    padding-left: 4px;
  }
}

.back-link {
  padding: 8px;

  a {
    color: #3b9198;
    transition: all 0.3s;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    @media (max-width: 768px) {
      font-size: 12px;
    }

    &:hover {
      color: #255d62;
    }
  }
}

footer {
  background-color: #3b9198;
  grid-area: footer;
}
</style>
