<template>
  <div class="game-container" :style="`--tile-count: ${tileCount};`">
    <GameLogo/>
    <div class="game-gui">
      <div class="game-ctrl">
        <button class="btn btn-start" @click="startGame">Старт</button>
        <div class="game-logo-description">
          <p>
            Для изменения направления используйте клавиши
          </p>
          <p>
            "влево" <img src="@/assets/arrow-l.png" alt="arrow-l">
            и
            "вправо"<img src="@/assets/arrow-r.png" alt="arrow-r">
            на клавиатуре
          </p>
        </div>
        <GameScore :score="score.pts"/>
      </div>
    </div>

    <GameBoard :tileCount="tileCount" :snake="snake" :food="food" :boom="boom"/>

    <div class="game-btn-wrapper">
      <button class="btn btn-ctrl" @click="changeDirection('left')">
        <!-- icon666.com - MILLIONS vector ICONS FREE -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 16 16">
          <path fill="#444444"
                d="M8 0c-3 0-5.6 1.6-6.9 4.1l-1.1-1.1v4h4l-1.5-1.5c1-2 3.1-3.5 5.5-3.5 3.3 0 6 2.7 6 6s-2.7 6-6 6c-1.8 0-3.4-0.8-4.5-2.1l-1.5 1.3c1.4 1.7 3.6 2.8 6 2.8 4.4 0 8-3.6 8-8s-3.6-8-8-8z"/>
        </svg>
      </button>
      <button class="btn btn-ctrl" @click="changeDirection('right')">
        <!-- icon666.com - MILLIONS vector ICONS FREE -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 16 16" style="transform: scaleX(-1);">
          <path fill="#444444"
                d="M8 0c-3 0-5.6 1.6-6.9 4.1l-1.1-1.1v4h4l-1.5-1.5c1-2 3.1-3.5 5.5-3.5 3.3 0 6 2.7 6 6s-2.7 6-6 6c-1.8 0-3.4-0.8-4.5-2.1l-1.5 1.3c1.4 1.7 3.6 2.8 6 2.8 4.4 0 8-3.6 8-8s-3.6-8-8-8z"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import GameLogo from "@/components/GameLogo";
import GameScore from "@/components/GameScore";
import GameBoard from "@/components/GameBoard";
import {mdiRotateLeft, mdiRotateRight} from "@mdi/js";

import helpersMixin from "@/mixins/helpersMixin";
import methodsMixin from "@/mixins/methodsMixin";

export default {
  title: "SnakeView",
  mixins: [helpersMixin, methodsMixin],
  components: {
    GameLogo,
    GameScore,
    GameBoard,
  },
  data() {
    return {
      directions: ["up", "down", "left", "right"],
      tileCount: 14,

      snake: [
        {
          x: Math.floor(this.tileCount / 2),
          y: Math.floor(this.tileCount / 2),
        },
      ],
      food: {
        x: this.randomOf(this.tileCount),
        y: this.randomOf(this.tileCount),
      },

      isRunning: false,
      direction: "right",
      score: {id: null, pts: 0},
      highscores: [],
      lastPlayedScore: null,
      boom: false,

      // Icons
      mdiRotateLeft,
      mdiRotateRight,
    };
  },
  mounted() {
    this.loadHighscores();
    window.addEventListener("keydown", this.handleKeydown);
  },
  methods: {
    startGame() {
      this.resetGame();
      if (!this.isRunning) {
        this.isRunning = true;
        this.gameLoop();
      }
    },

    resetGame() {
      this.score.pts = 0;
      this.boom = false
      this.snake = [
        {
          x: Math.floor(this.tileCount / 2),
          y: Math.floor(this.tileCount / 2),
        },
      ];
      this.direction = this.directions[this.randomOf(this.directions.length)];
      this.food = {
        x: this.randomOf(this.tileCount),
        y: this.randomOf(this.tileCount),
      };
    },

    changeDirection(newDirection) {
      if (!this.isRunning) return;

      switch (this.direction) {
        case "right":
          this.direction = newDirection === "left" ? "up" : "down";
          break;
        case "left":
          this.direction = newDirection === "left" ? "down" : "up";
          break;
        case "up":
          this.direction = newDirection === "left" ? "left" : "right";
          break;
        case "down":
          this.direction = newDirection === "left" ? "right" : "left";
          break;
      }
    },

    gameLoop() {
      if (!this.isRunning) return;

      const newHead = this.getNewHead();

      if (this.isSnake(newHead.x, newHead.y)) {
        this.saveScore();
        this.isRunning = false;
        this.boom = true
        return;
      }

      this.snake.unshift(newHead);

      if (this.isFood(newHead.x, newHead.y)) {
        this.food = {
          x: this.randomOf(this.tileCount),
          y: this.randomOf(this.tileCount),
        };
        this.score.id = Date.now();
        this.score.pts += 1;
      } else {
        this.snake.pop();
      }

      setTimeout(this.gameLoop, 220);
    },

    getNewHead() {
      const head = this.snake[0];
      let newHead;

      switch (this.direction) {
        case "right":
          newHead = {x: (head.x + 1) % this.tileCount, y: head.y};
          break;
        case "left":
          newHead = {
            x: (head.x - 1 + this.tileCount) % this.tileCount,
            y: head.y,
          };
          break;
        case "up":
          newHead = {
            x: head.x,
            y: (head.y - 1 + this.tileCount) % this.tileCount,
          };
          break;
        case "down":
          newHead = {x: head.x, y: (head.y + 1) % this.tileCount};
          break;
      }

      return newHead;
    },

    saveScore() {
      const scores = JSON.parse(localStorage.getItem("highscores")) || [];
      const newScore = {
        id: this.score.id,
        pts: this.score.pts,
      };

      const scoreExists = scores.some((s) => s.id === newScore.id);
      if (scoreExists) return;

      scores.push(newScore);
      scores.sort((a, b) => b.pts - a.pts);
      scores.splice(3);

      while (scores.length < 3) {
        scores.push({id: null, pts: 0});
      }

      localStorage.setItem("highscores", JSON.stringify(scores));
      localStorage.setItem("lastPlayedScore", JSON.stringify(newScore));

      this.lastPlayedScore = newScore;
      this.highscores = scores;
    },

    loadHighscores() {
      try {
        const scores = JSON.parse(localStorage.getItem("highscores")) || [];
        const lastScore = JSON.parse(localStorage.getItem("lastPlayedScore"));

        while (scores.length < 3) {
          scores.push({id: null, pts: 0});
        }

        this.highscores = scores;

        if (lastScore) {
          this.lastPlayedScore = lastScore;
        }
      } catch (error) {
        console.error("Error loading top scores from local storage:", error);
      }
    },

    handleKeydown(e) {
      if (e.key === "ArrowLeft") {
        this.changeDirection("left");
      } else if (e.key === "ArrowRight") {
        this.changeDirection("right");
      }
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter.woff2") format("woff2"),
  url("../fonts/inter.woff") format("woff"),
  url("../fonts/inter.ttf") format("truetype");
}

/* Variables */
:root {
  --col-prim: #3b9198;
  --col-sec: #255d62;
  --col-food-apple: #e03131;
  --col-light-grey: #e1edf0;
  --col-txt-prim: #ffffff;
  --col-txt-sec: #c0c0c0;
  --col-txt-dark: #444444;
}

/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  touch-action: manipulation;
  user-select: none;
}

html {
  font-size: 62.5%;
  overflow: hidden;
}

body {
  background-color: var(--col-light-grey);
  font-family: "Inter", Fallback, sans-serif;
}

html,
body {
  height: 100dvh;
}

/* Layout */
.game-container {
  padding: 0;
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: auto auto 20px;
  height: 92vh;
}

.game-container > * {
  min-width: 0;
}

.game-gui {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1366px) {
    margin-top: 10px;
  }
}

.game-ctrl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.game-btn-wrapper {
  display: flex;
  gap: 3rem;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 1367px) {
    display: none;
  }
}

/* Buttons */
.btn {
  background-color: var(--col-prim);
  border: 0;
  border-bottom: 0.4rem solid var(--col-sec);
  border-radius: 1rem;
  color: white;
  cursor: pointer;
  height: 40px;

  svg {
    height: 100%;

    path {
      fill: white;
    }

  }
}

.btn:active {
  border-bottom: 0.25rem solid var(--col-sec);
}

.btn-start {
  padding: 1rem 2rem;
z-index: 1000;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-ctrl {
  padding: 4px;
  width: 100%;
}

/* Media queries */
@media (max-width: 380px) {
  html {
    font-size: 50%;
  }

  .game-container {
    gap: 1rem;
  }

  .game-ctrl {
    flex-direction: row;
  }
}

.game-logo-description {
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap: 1rem;
  }

  img {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 1366px) {
    display: none;
  }
}
</style>
